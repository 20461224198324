import reactLogo from './assets/icons/manifest-icon-512.maskable.png'
import { Box, Button, forwardRef, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './localization/i18n';
import { Fragment, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale} from  "react-datepicker";
import { sv } from 'date-fns/locale/sv';
import AboutFrame from './_components/about';
import { IoMdClose } from "react-icons/io";
import { TbArrowBigUpFilled } from "react-icons/tb";

function App() {
    const { t, i18n } = useTranslation();
    const [showAbout, setShowAbout] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    let now = new Date();
    const data = new Array(40).fill(1); 
    const [startDate, setStartDate] = useState(now);
    const [_now, set_Now] = useState(moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'))
    registerLocale('sv', sv)

    const getText = (day:any) => {
      let dayIndex = day+1;
      let textLink = 'screens.rearing.text.'+dayIndex.toString();
      let text = t(textLink)
      if(text.substring(0,7)!='screens') {
        return text;
      }else{
        return '';
      }      
    }

    const getWeekday = (day:any) => {
      let wd = moment(_now).add(day,'days').day()
      let g = t('screens.rearing.days.'+wd)
      return g
    }

    const getTextColor = (day:any) => {
      let dayIndex = day+1;
      let textLink = 'screens.rearing.dayColor.'+dayIndex.toString();
      let text = t(textLink)
      if(text.substring(0,7)!='screens') {
        return text;
      }else{
        return '';
      }      
    }

    const changeDate = (date:any) => {
      set_Now(moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD'))
    }


    const getQueenColor = () => {
      let aktYear = new Date().getFullYear().toString().substring(3);

      switch (aktYear) {
        case "0" || "5": {
          return "blue";
        }
        case "1" || "6": {
          return "white";
        }
        case "2" || "7": {
          return "red";
        }
        case "3" || "8": {
          return "#dede47";
        }
        case "4" || "9": {
          return "green";
        }
      }
    }

    const getQueenTextColor = () => {
      let aktYear = new Date().getFullYear().toString().substring(3);

      switch (aktYear) {
        case "0" || "5": {
          return "white";
        }
        case "1" || "6": {
          return "black";
        }
        case "2" || "7": {
          return "white";
        }
        case "3" || "8": {
          return "black";
        }
        case "4" || "9": {
          return "white";
        }
      }
    }

    const ExampleCustomInput = forwardRef(
      ({ value, onClick, className }, ref) => (
        <Button className={className} onClick={onClick} ref={ref} backgroundColor={'white'} color={'black'}>
          {value} 
        </Button>
      ),
    );

    return (
      <>
          <>
          {showHelp && (
            <>
            <VStack w={'100%'} h={'calc( 100vh - 0px)'} overflowY={'hidden'} backgroundColor={'black'} color={'white'} position={'absolute'} top={0} left={0} zIndex={1000} opacity={0.7} onClick={()=>setShowHelp(false)} />
            <VStack w={'100%'} h={'calc( 100vh - 0px)'} overflowY={'hidden'} backgroundColor={'none'} color={'white'} position={'absolute'} top={0} left={0} zIndex={1100} onClick={()=>setShowHelp(false)}>
              <Box backgroundColor={'white'} color={'black'} p={5} borderRadius={20} position={'absolute'} top={'120px'}>
                <Text>{t('screens.rearing.helpDate')}</Text>
                <Box position={'absolute'} top={'-30px'} left={'45%'}><TbArrowBigUpFilled color='white' fontSize={'40px'}/></Box>
              </Box>
            </VStack>
            </>
          )}
          <VStack flex={1} overflowY={'hidden'} backgroundColor={getQueenColor()} color={getQueenTextColor()}>
            <VStack p={'10px'} w={'100%'}>
              <HStack w={'100%'} justifyContent={'space-between'}>
                <HStack w={'80px'} backgroundColor={'white'} borderRadius='full' h={'80px'}>
                  <Image src={reactLogo} alt="Strömbergs Bisysslor" borderRadius='full' w={'80px'} h={'80px'} boxSize={'80px'}/>
                </HStack>
                <VStack>
                  <Text fontSize={'15px'}>{t('screens.rearing.setDate')}</Text>
                  <VStack>            
                    <DatePicker selected={startDate} onChange={(date) => {setStartDate(date as any);changeDate(date)}} locale="sv" dateFormat={'YYYY-MM-dd'} customInput={<ExampleCustomInput className="example-custom-input" />} />
                  </VStack>
                </VStack>
                <VStack height={'80px'} alignItems={'top'}>
                  {i18n.language=='en' && (
                      <Button onClick={()=>i18n.changeLanguage("sv")} size='xs' backgroundColor={'white'} color={'black'}>Svenska</Button>
                  )}
                  {i18n.language=='sv' && (
                      <Button onClick={()=>i18n.changeLanguage("en")} size='xs' backgroundColor={'white'} color={'black'}>English</Button>
                  )}
                  <Button onClick={()=>setShowAbout(true)} size='xs' backgroundColor={'white'} color={'black'}>{t('screens.rearing.about')}</Button>
                  <Button onClick={()=>setShowHelp(true)} size='xs' backgroundColor={'white'} color={'black'}>{t('screens.rearing.help')}</Button>
                </VStack>
              </HStack>            
            </VStack>
            <VStack minW={'100%'} p={'0px'} gap={1} h={'calc(100vh - 154px)'} overflowY={'scroll'} backgroundColor={'#FFF'} m={'2px'} color={'black'}>
              {data.map((t:any,i:any)=>(                            
                <HStack key={'dag'+i} w={'100%'} justifyContent={'flex-start'} borderWidth={1} m={0} fontSize={'13px'} backgroundColor={getTextColor(i)} p={'2px'}>
                    <HStack  w={'100px'} justifyContent={'space-between'}>
                      <Text width={'20px'} textAlign={'center'}>{getWeekday(i)}</Text> 
                      <Text>{moment(_now).add(i,'days').format('YYYY-MM-DD').toString()}</Text> 
                    </HStack>
                    <Text maxWidth={'calc(100vw - 120px)'}>{getText(i)}</Text>
                </HStack>
                ))}
            </VStack>
            <Text marginBottom={'10px'}>{t('screens.rearing.thisyearqueencolor')}</Text>            
          </VStack>
          </>
        {showAbout && (
          <>
          <VStack w={'100%'} h={'calc( 100vh - 0px)'} overflowY={'hidden'} backgroundColor={'black'} color={'white'} position={'absolute'} top={0} left={0} zIndex={1000} opacity={0.7} onClick={()=>setShowAbout(false)} />
          <VStack w={'100%'} h={'calc( 100vh - 0px)'} overflowY={'hidden'} backgroundColor={'none'} color={'white'} position={'absolute'} top={0} left={0} zIndex={1100} onClick={()=>setShowAbout(false)}>
            <AboutFrame/>
          </VStack>
          </>
        )}
        
      </>
    )
}

export default App
