import { AbsoluteCenter, Box, Divider, HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next';

const AboutFrame = () => {
    const { t } = useTranslation();

    return (
        <VStack flex={1} backgroundColor={'white'} color={'black'} m={'30px'} mt={'20%'} mb={'140%'} p={'10px'} borderRadius={'20px'}>
            <VStack>
                <Text fontSize={'30px'}>{t('screens.about.title')}</Text>
                <Text fontSize={'15px'}>{t('screens.about.text')}</Text>
                <Box position='relative' borderTopWidth={1} borderColor={'red'} w={'70%'} m={'10px'}>
                </Box>
                <Text fontSize={'18px'} fontWeight={'500'}>Nicklas Strömberg</Text>
                <Text lineHeight={1}>Strömbergs Bisysslor, {t('screens.about.sweden')}</Text>
                <Text lineHeight={1}>nicklas@strombergsbisysslor.se</Text>
            </VStack>
            
        </VStack>
    )
}

export default AboutFrame