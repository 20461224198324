import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, sv } from "./translations";

const STORE_LANGUAGE_KEY = "settings.lang";

const languageDetectorPlugin = {
    type: "languageDetector" as const,
    async: true,
    init: () => { },
    detect: async function (callback: (lang: string) => void) {
        try {
            // get stored language from Async storage
            // put your own language detection logic here
            let lang2 = await localStorage.getItem(STORE_LANGUAGE_KEY)!
            if (lang2) {
                //if language was stored before, use this language in the app
                return callback(lang2);
            } else {
                //if language was not stored yet, use english
                return callback("en");
            }           
            
        } catch (error) {
            console.log("Error reading language", error);
        }
    },
    cacheUserLanguage: async function (lang2: string) {
        try {
            //save a user's language choice in Async storage
            await localStorage.setItem(STORE_LANGUAGE_KEY, lang2);
        } catch (error) { }
    },
};
const resources = {
    en: {
        translation: en,
    },
    sv: {
        translation: sv,
    },
};

i18n.use(initReactI18next).use(languageDetectorPlugin).init({
    resources,
    compatibilityJSON: 'v3',
    // fallback language is set to english
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});
export default i18n;